.LoadingIcon {
  position: relative;
  width: 100%;
  height: 100%;

  &__Icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
  }

  &__Ball {
    width: 50%;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    transform-origin: 100% 50%;
    left: 0;

    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #333;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ._ball-1::before {
    animation: LoadingIcon__Icon 1s linear 0s infinite;
  }

  ._ball-2 {
    transform: rotate(45deg);
  }

  ._ball-2::before {
    animation: LoadingIcon__Icon 1s linear -0.125s infinite;
  }

  ._ball-3 {
    transform: rotate(90deg);
  }

  ._ball-3::before {
    animation: LoadingIcon__Icon 1s linear -0.25s infinite;
  }

  ._ball-4 {
    transform: rotate(135deg);
  }

  ._ball-4::before {
    animation: LoadingIcon__Icon 1s linear -0.375s infinite;
  }

  ._ball-5 {
    transform: rotate(180deg);
  }

  ._ball-5::before {
    animation: LoadingIcon__Icon 1s linear -0.5s infinite;
  }

  ._ball-6 {
    transform: rotate(225deg);
  }

  ._ball-6::before {
    animation: LoadingIcon__Icon 1s linear -0.625s infinite;
  }

  ._ball-7 {
    transform: rotate(270deg);
  }

  ._ball-7::before {
    animation: LoadingIcon__Icon 1s linear -0.75s infinite;
  }

  ._ball-8 {
    transform: rotate(315deg);
  }

  ._ball-8::before {
    animation: LoadingIcon__Icon 1s linear -0.875s infinite;
  }

  @keyframes LoadingIcon__Icon {
    0% {
      width: 12px;
      height: 12px;
      opacity: 1;
    }

    100% {
      width: 6px;
      height: 6px;
      opacity: 0.2;
      margin-left: 7px;
    }
  }
}
