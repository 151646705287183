body {
  background-color: $main-background-color;
}

h2 {
  small {
    color: #777;
    font-size: 16px;
  }
}

main {
  min-height: 80vh;
  padding-top: 76px;
}

.invisible {
  height: 0;
  width: 0;
  display: none;
  visibility: hidden;
}

.caution {
  color: #ff0;
}

.text-right {
  text-align: right;
}

.height-6 {
  height: 60px;
}

.color-blue {
  color: #2832c2;
}

.color-red {
  color: #f00;
}

.text-dark-grey {
  color: $color-dark-grey;
}

.text-orange {
  color: $color-orange;
}

.text-red {
  color: $color-red;
}

.text-blue {
  color: $color-blue;
}

.text-accent-red {
  color: #e1583a;
}

.text-theme-blue {
  color: #334e9d;
}

.text-color-fci {
  color: #10544c !important;
}

.border-blue {
  border: 1px solid $color-blue;
}

.border-red {
  border: 1px solid $color-red;
}

.border-accent-red {
  border: 1px solid #e1583a;
}

.border-theme-blue {
  border: 1px solid #334e9d;
}

.border-theme-fci {
  border: 1px solid #10544c;
}

.bg-red {
  background-color: $color-red;
}

.bg-blue {
  background-color: $color-blue;
}

.bg-pale-blue {
  background-color: $color-pale-blue;
}

.bg-pink {
  background-color: rgba($color: $color-red, $alpha: 0.05);
}

.bg-accent-red {
  background-color: rgba($color: #e1583a, $alpha: 0.05);
}

.bg-theme-blue {
  background-color: #334e9d;
}

.color-dark-blue {
  background-color: #003e83;
}

.term {
  max-width: 800px;
  margin: auto;
}

.term-content {
  height: 120px;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  padding: 8px;
  border: solid 1px black;
  overflow: auto;
}

.term-content-table {
  border-collapse: collapse;
}

.term-content-table td {
  border: 1px solid black;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-group {
  display: block;
}

.btn-group .date-picker-cursor-left {
  position: absolute !important;
  left: 20px;
  top: 0;
  font-size: 12px;
}

.btn-group .date-picker-cursor-right {
  position: absolute !important;
  right: 20px;
  top: 0;
  font-size: 12px;
}

.date-picker-fix {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: $main-background-color;
}

.mx-width-540 {
  max-width: 540px;
}

.font-size-bigger {
  font-size: 1.5em;
}

.font-size-double {
  font-size: 2em;
}

.box-shadow-bottom {
  box-shadow: 0 8px 5px -2px rgba(0, 0, 0, 1);
}

.btn-theme-blue {
  background-color: #334e9d;

  &:hover {
    background-color: #557fff;
  }
}

.btn-theme-fci {
  background-color: #10544c;

  &:hover {
    background-color: #21b4a2;
  }
}

.margin-tp-5 {
  margin-top: 5rem;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-nallow {
  max-width: 480px;
  margin-right: auto;
  margin-left: auto;
}

.font-bold {
  font-weight: bold;
}

.font-weight-medium {
  font-weight: 600;
}

.card {
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(55, 138, 221, 0.13);
  border-radius: 12px;
  border: 0;

  .card-icon {
    width: 116px;
    margin: 0 auto;
    margin-top: -76px;
    margin-bottom: 15px;
    border-radius: 58px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &-line {
    overflow: hidden;

    .card-header {
      background-color: $color-sns-line;
      color: #fff;
      letter-spacing: 0.1rem;
    }

    p {
      line-height: 1.6;
    }

    .line-link {
      background-color: #00c300;
      color: #fff;
      border-radius: 8px;
      padding: 10px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.alert {
  border: 0;

  &-primary {
    background-color: #ebf8ff;
  }

  &__icon {
    position: relative;
    top: 2px;
  }
}

.text-bg {
  &__gray {
    background-color: $main-background-color;
  }
}

.border-dashed {
  /* prettier-ignore */
  background-image:
    linear-gradient(to right, $input-border-color 4px, transparent 4px),
    linear-gradient(to bottom, $input-border-color 4px, transparent 4px),
    linear-gradient(to left, $input-border-color 4px, transparent 4px),
    linear-gradient(to top, $input-border-color 4px, transparent 4px);
  // NOTE: eslint と prettier のルールが競合するため
  background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-position: left top, right top, right bottom, left bottom;
}

.upload-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 20px;
  }
}

.btn {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 600;
  border: 0;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  &-secondary {
    color: #04162e;
    background-color: #d4daed;

    &:focus,
    &:active,
    &:hover {
      color: #04162e !important;
      background-color: #d4daed !important;
    }
  }

  &-danger {
    color: #e82957;
    background-color: #ffeaef;

    &:focus,
    &:active,
    &:hover {
      color: #e82957 !important;
      background-color: #ffeaef !important;
      opacity: 0.8;
    }
  }
}

.h-border-left {
  position: relative;
  padding-top: 4px;
  padding-left: 16px;
  min-height: 32px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    border-left: 4px solid #ce3131;
    height: 32px;
  }
}
