$header-color: #334e9d;
$footer-color: #4d5671;
$main-background-color: #f1f3f9;
$background-color: #fff9f0;
$color-blue: #4b7bd9;
$color-pale-blue: #f1f5ff;
$color-orange: #e1583a;
$color-dark-grey: #051034;
$color-red: #ce3131;
$color-sns-line: #00c300;
$input-border-color: #d4daed;
