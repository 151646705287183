.bg-blue {
  background-color: #0805ce;
}

.text-blue {
  color: #0027a7;
}

.text-theme-blue {
  color: #334e9d;
}

.border-theme-blue {
  border: 1px solid #334e9d;
}

.border-content {
  height: 50px;
  padding: 10px;
}

.justify-center {
  justify-content: center;
}

.thanks-counseling-img-css {
  height: 200px;
  width: 150px;
}

.mx-width-40 {
  max-width: 40%;
}

.mx-width-46 {
  max-width: 46%;
}

.mx-width-52 {
  max-width: 52%;
}
