.background-first-examination-input {
  background-color: #f3ede4;
  height: inherit;
}

.container-first-examination {
  flex-direction: column;
  align-items: center;
}

.display-inline {
  display: inline !important;
}

.display-block {
  display: block !important;
}

.width-80 {
  width: 80%;
}

.width-100 {
  width: 100%;
}

.lp-message-textarea {
  border-radius: 4px;
}

.input-text-style {
  border: white;
  border-radius: 8px;
  height: 60px;
}

.check-border {
  border: 2px solid orange;
  border-radius: 6px;
  box-shadow: 0 5px 0 orange;
  margin: 20px;
  padding: 10px;
  background-color: white;
}

.explanation {
  border: 1px solid orange;
  border-radius: 4px;
  background-color: white;
  padding: 8px;
}

.submit-button-LP {
  background-color: orange;
  color: white;
  border: 0 solid orange;
  border-radius: 6px;
  padding: 3px;
  margin-top: 20px;
  width: 100%;
}

.submit-button-FCI {
  background-color: #13655c;
  color: white;
  border: 0 solid #13655c;
  border-radius: 30px;
  padding: 3px;
  margin-top: 20px;
  width: 70%;
  font-size: 1.5em;
  font-weight: bold;
  box-shadow: 0 8px 0 0 #0a3530;
}

.link-button-FCI {
  background-color: #13655c;
  color: white;
  border: 0 solid #13655c;
  border-radius: 10px;
  padding: 3px;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
}

.height-50 {
  height: 50px;
}

.radio-LP {
  transform: scale(2);
}

.display-radio-sentense-LP {
  display: flex;
  align-items: center;
}

.form-header {
  margin-top: 30px;
  height: 60px;
}

.register-label {
  text-align: center;
  font-weight: bold;
  color: #dc6d07;
  font-size: 1.3em;
}

.register-label-fci {
  text-align: center;
  font-weight: bold;
  color: #13655c;
  font-size: 1.3em;
}
