.reservation-title {
  font-size: 2em;
  color: rgb(92, 122, 221);
}

.schedule-calendar-wrapper {
  display: block;
}

/*************  カレンダー  ***************/

.hours {
  background-color: rgb(220, 232, 255);
  height: 35px;
  margin: 8px;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 2px;
  cursor: pointer;

  .hour {
    display: inline-block;
    height: 25px;
    padding-left: 15px;
    vertical-align: super;
    text-align: left;
  }

  /* 空き枠５つ以上。丸表示 */
  .space-condition-good {
    content: "";
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid #355fdd 1px;
    box-sizing: border-box;
    background-color: rgb(220, 232, 255);
    top: 10%;
    left: 2%;
  }

  .space-condition-few {
    width: 0;
    height: 0;
    position: relative;
    border-top: 20px solid transparent;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 20px solid rgb(248, 194, 113);
    top: -55%;
    left: 2%;
  }

  .space-condition-none {
    display: block;
    width: 24px;
    height: 24px;
    background: rgb(220, 232, 255);
    position: relative;
    left: 2%;
  }

  .space-condition-none::before,
  .space-condition-none::after {
    content: "";
    display: block;
    width: 107%; /* バツ線の長さ */
    height: 2px; /* バツ線の太さ */
    background: grey;
    transform: rotate(45deg);
    transform-origin: 0% 50%;
    position: absolute;
    top: calc(18%);
    left: 14%;
  }

  .space-condition-none::after {
    transform: rotate(-45deg);
    transform-origin: 100% 50%;
    left: auto;
    right: 14%;
  }

  div {
    vertical-align: top;
    align-items: center;
    position: relative;
    top: 8%;
  }

  button {
    content: "";
    display: inline-block;
    cursor: pointer;
    background: transparent;
    color: #355fdd;
    border-radius: 4px;
    font-size: 1rem;
    border: none;
    outline: none;
    line-height: 1%;
    width: 40px;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #355fdd;
      border-right: 2px solid #355fdd;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      display: inline-block;
    }
  }
}

.minutes {
  /* 画像の高さの半分 */
  border-radius: 57px;
  border: 1px solid #355fdd;
  background-color: white;
  height: 30px;
  margin: 8px;
  padding: 3px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  cursor: pointer;

  .minute {
    display: inline-block;
    height: 25px;
    vertical-align: super;
    margin-right: auto;
    text-align: right;
    padding-left: 10px;
  }

  .minute-disabled {
    display: inline-block;
    height: 25px;
    width: 60px;
    vertical-align: super;
    margin-right: auto;
    text-align: right;
    color: gray;
  }

  button {
    content: "";
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
    border: none;
    outline: none;
    background: transparent;
    line-height: 1%;
    width: 40px;
    background-color: #334e9d;
    height: inherit;
    border-radius: 0 30px 30px 0;
    transform: translateX(10px);

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      display: inline-block;
    }
  }

  .reservation-button-disabled {
    background-color: grey !important;
    cursor: unset !important;
  }
}

/* 日付表示 */
#schedule-calendar-header {
  text-align: center;
  font-size: 24px;
  width: 100%;
  margin: 1rem 0 0;
  background-color: #f3f3f3;
  justify-content: space-between;
  align-items: center;
}

/* カレンダー */
#schedule-calendar {
  text-align: center;
}

.schedule-calendar-table {
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid #f0f0f0;
  }

  th {
    color: #000;
  }

  th,
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-bottom: 1px 0 rgb(180, 180, 180) !important;
  }

  .dayOfWeek {
    /* 日曜日 */
    th:first-child {
      color: red;
    }

    /* 土曜日 */
    th:last-child {
      color: blue;
    }
  }

  /* 前後月の日付 */
  td.disabled {
    color: #ccc;
  }

  /* 本日 */
  td.today {
    background-color: #334e9d;
    color: #fff;
  }

  .table-day {
    cursor: pointer;

    &:hover {
      background-color: #d1d8fc;
    }
  }

  button {
    content: "";
    display: inline-block;
    cursor: pointer;
    background: transparent;
    color: #355fdd;
    border-radius: 4px;
    font-size: 1rem;
    border: none;
    outline: none;
    line-height: 1%;
    width: 40px;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #355fdd;
      border-right: 2px solid #355fdd;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      display: inline-block;
    }
  }
}

/* ボタン */
#schedule-calendar-header button {
  cursor: pointer;
  background: #334e9d;
  color: #fff;
  border: 1px solid #334e9d;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  margin: 1rem 0;
}

#schedule-calendar-header button:hover {
  background-color: #566099;
  border-color: #566099;
}

#prev {
  float: left;
}

#next {
  float: right;
}

/*************  時間表示コンポーネント  ***************/
.intra-day-wrapper {
  text-align: center;
}

#intra-day-header {
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin: 2rem 0 0 0;
  color: rgb(70, 106, 223);
}

#intra-day {
  align-items: center;
  width: 100%;
  margin: 1rem 0 0 0;
}

#intra-day-hours {
  list-style: none;
  // text-align: center;
  display: contents;
}

.hours-wrapper {
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

#intra-day-minutes-list {
  list-style: none;
  padding: 0;
}

#intra-day-minutes {
  width: 80%;
  margin: 0 auto;
}

.hour-open {
  display: block;
  animation: fadeIn 1.5s ease 0s 1 normal;
}

/* hour-open用 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  } /* 始め */
  100% {
    opacity: 1;
  } /* 終わり */
}

/* hour-open用：古いブラウザ用 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hour-close {
  display: none;
}

#intra-day-minutes-list li {
  text-align: right;
}

.reservation-disabled {
  background-color: rgb(206, 206, 206) !important;
  border: 1px solid rgb(206, 206, 206) !important;
}
