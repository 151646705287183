.footer {
  min-height: 80px;
  color: #fff;
  background-color: $footer-color;
  padding: 30px 16px 8px 16px;
  position: relative;
  line-height: 200%;
  z-index: 10;

  ul {
    list-style: none;
    padding-left: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .footer-link {
    font-size: 12px;
  }

  .footer-tel {
    color: #4d5671;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 14px;

    p {
      font-size: 28px;
      margin-bottom: -5px;
    }

    small {
      font-weight: 600;
    }
  }

  .footer-line-link {
    background-color: #00c300;
    color: #fff;
    border-radius: 8px;
    padding: 10px 30px;

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .footer-copy {
    margin-top: 30px;
    font-size: 11px;
  }
}
