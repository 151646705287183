@import "~select2";
@import "./public/index.scss";

.select2-container {
  .select2-selection {
    border: 1px solid #ced4da;

    &--single {
      height: 38px;

      .select2-selection {
        &__rendered {
          height: 38px;
          line-height: 38px;
        }

        &__arrow {
          height: 38px;
        }
      }
    }
  }
}
